// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import ruleBase from './ruleBase'


const rootReducer = {
  auth,
  navbar,
  layout,
  ruleBase
}

export default rootReducer
