import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE={
    leadChart:[]
}
export const ruleBaseSlice = createSlice({
    name: 'leadSourceChart',
    initialState:INITIAL_STATE,
    reducers: {
      setCurrentLeadData(state,action ){
        state.leadChart =action.payload
        console.log('lead-balance',action.payload)
        // state.leadChart=action.payload
        // if(action.payload.type==='all'){
        //     console.log('state>>>',action.payload?.data)
        //    return state.leadChart =action.payload?.data
        // }
        // const chartData = await leadService.leadSourceChart(year)
      }
    }
  })
  
  export const {setCurrentLeadData } = ruleBaseSlice.actions
  
  export default  ruleBaseSlice.reducer